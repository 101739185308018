var log = function (data) {
    var DEBUG = true;
    if (DEBUG) {
        console.log(data)
    }
};

var navigationModule = (function (window, document) {

    var navigationItems = document.querySelectorAll('.lb-navigation__item');

    // Navigation
    var navigationContentToAnimate = null;
    var navigationHeightToAnimate = 0;
    var navigationAnimationStartTime = null;
    var navigationAnimationTime = 225;

    // Accordion
    var accordionContentToAnimate = null;
    var accordionHeightToAnimate = 0;
    var accordionAnimationStartTime = null;
    var accordionAnimationTime = 225;

    var init = function () {
        log('navigationModule::init');
        setDetailImageHeight();
        initializeEventListener();
    };

    var setDetailImageHeight = function () {
        var detailBackgrounds = document.querySelectorAll('.lb-detail__background');

        for (var i = 0; i < detailBackgrounds.length; i++) {
            var detailBackground = detailBackgrounds[i];
            var descriptionContainer = detailBackground.parentElement;

            detailBackground.style.height = descriptionContainer.clientHeight + 'px';
        }
    };

    var addTutoring = function (e) {
        var currentSelectItem = e.currentTarget.parentNode.querySelector('select');
        var currentRow = currentSelectItem.parentNode.parentNode;
        var newButton = currentRow.querySelector('.lb-kontakt__select-tutoring__button').cloneNode(true);
        var currentValue = currentSelectItem.value;
        var options = currentSelectItem.options;

        var selectItems = document.querySelectorAll('.lb-kontakt__select-tutoring select');

        if (currentValue !== '' && selectItems.length < options.length - 1) {
            var row = document.createElement('div');
            row.classList.add('row');
            var column = document.createElement('div');
            column.classList.add('twelve', 'columns', 'lb-kontakt__select-tutoring');
            var label = document.createElement('label');
            label.setAttribute('for', 'nachhilfeart' + (selectItems.length + 1));
            label.hidden = true;
            var select = document.createElement('select');

            newButton.id = 'button-option-' + (selectItems.length + 1);

            select.classList.add('u-full-width');
            select.name = 'nachhilfeart' + (selectItems.length + 1);
            select.id = 'nachhilfeart' + (selectItems.length + 1);

            for (var i = 0; i < options.length; i++) {
                var option = document.createElement('option');
                option.value = options[i].value;
                option.text = options[i].text;

                select.add(option);
            }

            column.appendChild(label);
            column.appendChild(select);
            column.appendChild(newButton);
            row.appendChild(column);

            newButton.addEventListener('click', addTutoring);

            currentRow.parentNode.insertBefore(row, currentRow.nextSibling);
        } else if (currentValue === '') {
            window.alert('Vor dem Hinzufügen muss eine Nachhilfeart gewählt werden.')
        }
    };

    var initializeEventListener = function () {
        var scrollToNextSectionButton = document.querySelector('.lb-next-section a');
        var accordionElements = document.querySelectorAll('.lb-angebot-mobile__headline');
        var burgerNavButton = document.querySelector('.lb-navigation__burger-nav');
        var mobileNavItems = document.querySelectorAll('.mobile-navigation__list-container .lb-navigation__item');
        var lbNavigationSymbol = document.querySelector('.lb-navigation__symbol');
        var addTutButton = document.querySelector('.lb-kontakt__select-tutoring__button');

        if (lbNavigationSymbol !== null) {
            lbNavigationSymbol.addEventListener('click', selectNavigationItem);
        }

        if (navigationItems.length > 0) {
            for (var i = 0; i < navigationItems.length; i++) {
                navigationItems[i].addEventListener('click', selectNavigationItem)
            }
        }

        if (accordionElements.length > 0) {
            for (var i = 0; i < accordionElements.length; i++) {
                accordionElements[i].addEventListener('click', toggleAccordionElement);
            }
        }

        if (scrollToNextSectionButton !== null) {
            scrollToNextSectionButton.addEventListener('click', function (e) {
                scrollToSection(scrollToNextSectionButton.href);
            });
        }

        if(mobileNavItems.length > 0) {
            for (var i = 0; i < mobileNavItems.length; i++) {
                mobileNavItems[i].addEventListener('click', toggleMobileNavigation);
            }
        }

        if (burgerNavButton !== null) {
            burgerNavButton.addEventListener('click', toggleMobileNavigation);
        }

        if (addTutButton !== null) {
            addTutButton.addEventListener('click', addTutoring);
        }

        window.addEventListener('resize', setCardSize);

        setCardSize();
    };

    var selectNavigationItem = function (e) {
        var navigationItem = e.currentTarget;
        var destinationLink = navigationItem.querySelector('a').href;

        if (!navigationItem.classList.contains('lb-navigation__item--selected')) {
            for (var i = 0; i < navigationItems.length; i++) {
                navigationItems[i].classList.remove('lb-navigation__item--selected');
            }

            navigationItem.classList.add('lb-navigation__item--selected');
        }

        scrollToSection(destinationLink);
    };

    var scrollToSection = function (destinationLink) {
        var hashStart = destinationLink.indexOf('#');
        var destinationHash = destinationLink.substr(hashStart, destinationLink.length);
        var destinationElement = document.querySelector(destinationHash);

        scrollIt(destinationElement, 250, 'linear');
    };

    var setCardSize = function () {
        var cards = document.querySelectorAll('.lb-angebot');

        if (cards.length > 0 && window.innerWidth > 768) {
            var cardWidth = cards[0].clientWidth;

            for (var i = 0; i < cards.length; i++) {
                cards[i].style.height = cardWidth + 'px';
            }
        }

    };

    var toggleAccordionElement = function (e) {
        var accordionToAnimate = e.currentTarget;
        accordionContentToAnimate = accordionToAnimate.parentElement.querySelector('.lb-angebot-mobile__content');
        accordionHeightToAnimate = accordionContentToAnimate.querySelector('.lb-angebot-mobile__content__inner').clientHeight;

        if (accordionToAnimate.classList.contains('selected')) {
            accordionToAnimate.classList.remove('selected');
            requestAnimationFrame(hideAccordionAnimation);
        } else {
            accordionToAnimate.classList.add('selected');
            requestAnimationFrame(showAccordionAnimation);
        }
    };

    var showAccordionAnimation = function (timestamp) {
        if (accordionAnimationStartTime == null) {
            accordionAnimationStartTime = timestamp;
        }

        var progress = timestamp - accordionAnimationStartTime;
        var percentProgress = Math.min(progress / accordionAnimationTime, 1);
        var heightToAnimate = accordionHeightToAnimate * percentProgress;
        if (percentProgress < 1) {
            accordionContentToAnimate.style.height = heightToAnimate + 'px';
            requestAnimationFrame(showAccordionAnimation);
        } else {
            accordionContentToAnimate.style.height = heightToAnimate + 'px';
            accordionAnimationStartTime = null;
        }
    };

    var hideAccordionAnimation = function (timestamp) {
        if (accordionAnimationStartTime == null) {
            accordionAnimationStartTime = timestamp;
        }

        var progress = timestamp - accordionAnimationStartTime;
        var percentProgress = Math.min(progress / accordionAnimationTime, 1);
        var heightToAnimate = accordionHeightToAnimate - accordionHeightToAnimate * percentProgress;
        if (percentProgress < 1) {
            accordionContentToAnimate.style.height = heightToAnimate + 'px';
            requestAnimationFrame(hideAccordionAnimation);
        } else {
            accordionContentToAnimate.style.height = heightToAnimate + 'px';
            accordionAnimationStartTime = null;
        }
    };

    var toggleMobileNavigation = function (e) {
        var burgerNavButton = e.currentTarget;
        navigationContentToAnimate = document.querySelector('.mobile-navigation__row');
        navigationHeightToAnimate = document.querySelector('.mobile-navigation__list-container').clientHeight;

        if (navigationContentToAnimate.classList.contains('active')) {
            navigationContentToAnimate.classList.remove('active');
            burgerNavButton.classList.remove('active');
            requestAnimationFrame(hideNavigationAnimation);
        } else {
            navigationContentToAnimate.classList.add('active');
            burgerNavButton.classList.add('active');
            requestAnimationFrame(showNavigationAnimation);
        }
    };

    var showNavigationAnimation = function (timestamp) {
        if (navigationAnimationStartTime == null) {
            navigationAnimationStartTime = timestamp;
        }

        var progress = timestamp - navigationAnimationStartTime;
        var percentProgress = Math.min(progress / navigationAnimationTime, 1);
        var heightToAnimate = navigationHeightToAnimate * percentProgress;
        if (percentProgress < 1) {
            navigationContentToAnimate.style.height = heightToAnimate + 'px';
            requestAnimationFrame(showNavigationAnimation);
        } else {
            navigationContentToAnimate.style.height = heightToAnimate + 'px';
            navigationAnimationStartTime = null;
        }
    };

    var hideNavigationAnimation = function (timestamp) {
        if (navigationAnimationStartTime == null) {
            navigationAnimationStartTime = timestamp;
        }

        var progress = timestamp - navigationAnimationStartTime;
        var percentProgress = Math.min(progress / navigationAnimationTime, 1);
        var heightToAnimate = navigationHeightToAnimate - navigationHeightToAnimate * percentProgress;
        if (percentProgress < 1) {
            navigationContentToAnimate.style.height = heightToAnimate + 'px';
            requestAnimationFrame(hideNavigationAnimation);
        } else {
            navigationContentToAnimate.style.height = heightToAnimate + 'px';
            navigationAnimationStartTime = null;
        }
    };

    return {
        init: init
    }
})(window, document);

var formModule = (function (window, document, $) {
    var sendingMail = false;

    var init = function () {
        setEventListener();
    };

    var setEventListener = function () {
        var form = document.getElementById('lb-contact');

        if (form !== null) {
            form.addEventListener('submit', sendContactForm);
        }
    };

    var genFormInfos = function (infoElement, status, multiple) {
        var validationRow = document.querySelector('.lb-validation__row');
        var validationContainer = validationRow.querySelector('.lb-validation__container');
        var validationHeadline = validationRow.querySelector('.lb-validation__info-headline');
        var validationInfosContainer = validationRow.querySelector('.lb-validation__infos');

        validationRow.style.display = 'block';
        validationContainer.classList.remove('success');
        validationHeadline.style.marginBottom = '1rem';
        validationInfosContainer.innerHTML = '';


        if (status === 'success') {
            validationContainer.classList.add('success');
            validationHeadline.style.marginBottom = '0';
            validationHeadline.innerHTML = 'Das Formular wurde erfolgreich versendet.';
        } else {
            if (multiple === true) {
                validationHeadline.innerHTML = 'Das Formular konnte leider nicht versendet werden:';

                for (var i = 0; i < infoElement.length; i++) {
                    validationInfosContainer.append(infoElement[i]);
                }
            } else {
                validationHeadline.innerHTML = 'Das Formular konnte leider nicht versendet werden:';
                validationContainer.append(infoElement);
            }
        }
    };

    var sendContactForm = function (event) {
        event.preventDefault();

        var form = $(event.currentTarget);

        if (!sendingMail) {
            sendingMail = true;

            $.ajax({
                type: 'POST',
                url: base_url + 'sendmail/send',
                data: form.serializeArray(),
                dataType: 'json',
                cache: false,
                success: function (response) {
                    console.log(response);
                    sendingMail = false;
                    if (response.mailSent === true) {
                        genFormInfos('', 'success', false);
                    } else {
                        var validationErrors = $.parseHTML(response.validation);

                        genFormInfos(validationErrors, 'error', true);
                    }
                },
                error: function (error) {
                    sendingMail = false;

                    var errorInfo = document.createElement('div');
                    errorInfo.className = 'lb-validation__info';
                    errorInfo.innerHTML = 'Es ist ein unbekannter Fehler aufgetreten.<br> Bitte wenden Sie sich an den Seitenbetreiber.';
                    genFormInfos(errorInfo, 'error', false);
                }
            });
        } else {
            return false;
        }

        return false;
    };

    return {
        init: init,
    }
})(window, document, jQuery);


function scrollIt(destination, duration, easing, callback) {

    var easings = {
        linear: function (t) {
            return t;
        },
        easeInQuad: function (t) {
            return t * t;
        },
        easeOutQuad: function (t) {
            return t * (2 - t);
        },
        easeInOutQuad: function (t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
        easeInCubic: function (t) {
            return t * t * t;
        },
        easeOutCubic: function (t) {
            return (--t) * t * t + 1;
        },
        easeInOutCubic: function (t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        },
        easeInQuart: function (t) {
            return t * t * t * t;
        },
        easeOutQuart: function (t) {
            return 1 - (--t) * t * t * t;
        },
        easeInOutQuart: function (t) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
        },
        easeInQuint: function (t) {
            return t * t * t * t * t;
        },
        easeOutQuint: function (t) {
            return 1 + (--t) * t * t * t * t;
        },
        easeInOutQuint: function (t) {
            return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
        }
    };

    var start = window.pageYOffset;
    var startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    var documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    var destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop - 64;
    var destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }

    function scroll() {
        var now = 'now' in window.performance ? performance.now() : new Date().getTime();
        var time = Math.min(1, ((now - startTime) / duration));
        var timeFunction = easings[easing](time);
        window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

        if (window.pageYOffset === destinationOffsetToScroll) {
            if (callback) {
                callback();
            }
            return;
        }

        requestAnimationFrame(scroll);
    }

    scroll();
}

jQuery(document).ready(function () {
    navigationModule.init()
    formModule.init()
});
